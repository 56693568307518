import React from "react"
import Style from "./Heritage.module.scss"
import appPic from "../imgs/Harpers-Mansion-Berrima-Cafe.png"
import map from "../imgs/Trail North Bondi v1 Website.jpg"

const Heritage = () => (
    <div id="heritage">
        <div className={Style.container}>
            <img src={appPic} alt=""  className={Style.app}/>
            <div className={Style.text}>
                <p>If you are an organisation or council, why not do away with paper heritage trails and let it be digital? </p>
                <p>And if you were already contemplating going digital, don’t go to the expense of producing your own app! Instead, use ours for only $1,500 per year.</p>
                <p>Speak to our team today. Please book an appointment at a time that suits you on Calendly. </p>

            </div>
        </div>
        <div className={Style.container2}>
            <img src={map} alt=""  className={Style.img}/>
        </div>
        


    </div>
)

export default Heritage;