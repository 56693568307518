import React from "react"
import Style from "./Education.module.scss"
import kids from "../imgs/AdobeStock_288098121-website.jpg"
import student1 from "../imgs/U and Me Dave and John 11 RGB Website.jpg" 
import student2 from "../imgs/U and Me Dave and John 32 RGB website.jpg"
import student3 from "../imgs/WHY05027-Website.jpg"
import student4 from "../imgs/WHY00401 website.jpg"
import cameraStudent from "../imgs/U and Me Dave and John 28 RGB website.jpg"


const Education = () => (
    <div>
        <div id="education" className={Style.link}>&nbsp;</div>
        <div className={Style.container}>
            <div>
                <h4 className={Style.heading}>Education</h4>
                <p className={Style.text}>Yesterday Stories features a number of video stories linked to the school curriculum. Why not use our app and school resources to run your lessons? Contact our team to learn more on <a href="mailto:info@yesterdaystories.com.au">info@yesterdaystories.com.au</a></p>
            </div>
            <img src={kids} alt="education" className={Style.img}/>
        </div>
        
        <div className={Style.section2}>
            <img src={cameraStudent} alt="Camera Student" className={Style.cameraStudent}/>
            <h4 className={Style.heading}>Film-making Workshops</h4>
            <p>Why not book our team to work with your community and young people to produce stories. See some feedback on workshops run through school holidays </p>
        </div>

        <div className={Style.container}>
            <img src={student1} alt=""  className={Style.img}/>
            <img src={student2} alt=""  className={Style.img}/>
            <img src={student3} alt=""  className={Style.img}/>
            <img src={student4} alt=""  className={Style.img}/>

        </div>
    </div>
)
export default Education;
