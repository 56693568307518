import React, { useState } from "react";
import store from "./constants/store.constants"
import VideoThumbnail from "./components/VideoThumbnail";
import { Container, Row, Col } from "react-bootstrap";
import style from './HomeSectionOne1.module.scss';

import MungoRangertrans from "./imgs/Female-Factory-1.png";
import MapWithAppIcons from "./imgs/Map-With-App-Icons.png";
import map from "./imgs/map.jpg";
import indigenous_Remastered from "./imgs/indigenous Remastered.png";
import MuesumsGalleries from "./imgs/MuesumsGalleries.png";
import GooglePlay from "./imgs/GooglePlay.PNG";
import Appstore from "./imgs/Appstore.PNG";

const VIDEO_LINK = "https://media.yesterdaystories.net.au/static/site/female-factory-worker.mp4";
const VIDEO_NAME = "Female Factory Parramatta";
const VIDEO_DESCRIPTION = "Some estimates report 1 in 5 to 1 in 7 people in Australia are related to the women who went through the female factory in Parramatta.";
const VIDEO_SHARE_LINK = "https://media.yesterdaystories.net.au/pages/eadc4a60-4e39-11eb-a6e8-a94e99b45672.html";
//const VIDEO_THUMBNAIL = "https://media.yesterdaystories.net.au/prod/199e1f9c-f96d-41af-920b-c243aa02ec6a.png";
const VIDEO_THUMBNAIL = null;


function HomeSectionOne1() {

  const [popupEnabled, setPopupEnabled] = useState(false)

  const handleStoryClick = () => {
    setPopupEnabled(true)
  }

  return (
    <>
      <br/><br/><br/><br/><br/><br/><br/>
      <div className={style.homeSectionOne}>
        <Container className="HomeSectionOne1">
          <Row className="justify-content-lg-center center">
            <Col >
              <br />
              <div>
                <p className={style.mainText}>Richer connection to place and people when travelling</p>
              </div>
            </Col>
            <Col>
              <div>
                <img onClick={handleStoryClick} className="MungoRangertrans" src={MungoRangertrans} alt="" />
                
                  {popupEnabled && <div className="topHoveringVideo">
                    
                    <VideoThumbnail
                    closeMapPopup={() => setPopupEnabled(false)}
                    borderEnabled={true}
                    thumbnailAddress={VIDEO_THUMBNAIL}
                    videoAddress={VIDEO_LINK}
                    videoName={VIDEO_NAME}
                    videoDescription={VIDEO_DESCRIPTION}
                    shareLink={VIDEO_SHARE_LINK}
                    />
                  </div>}
                
                <img className="MapWithAppIcons" src={MapWithAppIcons} alt="" />

              </div>
            </Col>
          </Row>
          <Row lg={2} xs={1}>
            <Col className={style.subTitleWrapper}>
              <p className={style.subTitle}>Through stories we understand the layers of history and this helps us connect to place and the people of that place</p>
            </Col>
            <Col></Col>
          </Row>

          <Row className="justify-content-lg-center center">
            <Col >
              <div>
                <br/>
                <h1 className={style.getStarted}>Get started. it's free.</h1>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-lg-center center">
            <Col className="d-flex justify-content-end">
              <a target="_blank" rel="noopener noreferrer" href={store.googlePlayStore}>
                <img className={style.img_GooglePlay} src={GooglePlay} alt="" />
              </a>

            </Col>
            <Col className="d-flex justify-content-start">
              <a target="_blank" rel="noopener noreferrer" href={store.appStore}>
                <img className={style.img_Appstore} src={Appstore} alt="" />
              </a>

            </Col>
          </Row>
          <img className={style.img_indigenous_Remastered} src={indigenous_Remastered} alt="" />
          <img className={style.img_MuseumGalleries} src={MuesumsGalleries} alt="" />
        </Container>
        <img className="img_map" src={map} alt="" />
      </div>
    </>
  );
}
export default HomeSectionOne1;
