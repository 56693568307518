import React from "react"
import coins from "../imgs/georges-v-coins-2022-02-03-02-59-55-utc.jpg"
import Style from "./Reward.module.scss"

function Reward(){

    return (
        <div className={Style.container}>
            <div className={Style.text}>
                <h4 className={Style.heading} >Win prizes by registering!</h4>
                <p>Register on the Yesterday Stories app to accrue ‘coins’ while watching stories in location. The viewer who has accrued the most coins will win a prize! </p>
                <p>More information coming soon</p>
            </div>
            <img src={coins} alt="" className={Style.img}/>

        </div>
    );
}

export default Reward;