import React from "react";
import Style from "./Login.module.scss";
import adminStyle from "./Admin.module.scss";

function Filmmaker(props) {
    return (
        <div className={Style.background}>
            <div className={adminStyle.header} style={{background: "white"}}>
                <div className={adminStyle.close} onClick={()=>props.setfeed(false)}>X</div>
            </div>
            <iframe title="Jobs Feed" src='https://app.kitl.co/company-job-feed/56_07d6c19?showheader=1&shownav=0' width='90%' height='600' frameborder="0"></iframe> 

        </div>
    );
}
export default Filmmaker;
