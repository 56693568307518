import React from "react";
import { useState } from "react";
import { Container, Navbar, Nav, NavDropdown} from "react-bootstrap";
import logo from "./imgs/YS-Logo-01.svg";
import account from "./imgs/account.png";
import adPoster from "./posters/learn more about advertising.pdf";
import { PATREON_URL } from "./constants/url.constants";
import Style from "./NavHeader.module.scss";
import Login from "./pages/AuthPage";
import AdFrame from "./pages/AdFrame";
import Filmmaker from "./pages/Filmmaker";

function NavHeader() {

  const [show, setShow] = useState("none");
  const [admin, setAdmin] = useState(false);
  const [ad, setad] = useState(false);
  const [user, setUser] = useState(localStorage.getItem("auth")? true : false);
  const [jFeed, setFeed] = useState(false);

  const handleAdvertise = () => {
    window.open(adPoster);
    if(user){ 
      setad(true);
     } else {
      setShow("flex");}
  }

  return (
    <>
      <a href="/"><img src={logo} alt="Logo" className={Style.logo +" logo"} /></a>
      
      <Login setShow={setShow} show={show} admin={admin} setAdmin={setAdmin} user={user} setUser={setUser}/>
      {ad && <AdFrame setFrame={setad} />}
      {jFeed && <Filmmaker setfeed={setFeed} />}
      <Navbar collapseOnSelect expand="lg" variant="light" sticky="top" >
        <div className={Style.navbar}>
        <Container className="navContainer">
          <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{backgroundColor:"white"}}/>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto" style={{backgroundColor:"white", width: "min-content"}}>
              <Nav.Link className="linkText" href="#product">Product</Nav.Link>
              <Nav.Link className="linkText" href="#faq" style={{cursor:"help"}}>FAQs</Nav.Link>
              <NavDropdown align="end" className="linkText" title="Current News" id="collasible-nav-dropdown">
                <NavDropdown.Item className="linkText" href="#media">Media</NavDropdown.Item>
                <NavDropdown.Item className="linkText" href="#education">Education</NavDropdown.Item>
                {/* <NavDropdown.Item className="linkText" href="#filmComp">Film Competition</NavDropdown.Item> */}
              </NavDropdown>
              <NavDropdown align="end" style={{width:"10%"}} className={Style.noCaret + " linkText"} title={<img src={account} className={Style.acc} alt=""/>} id="basic-nav-dropdown">

                <NavDropdown.Item className="linkText"onClick={()=>handleAdvertise()}>Advertise</NavDropdown.Item>
                {user? 
                  <>
                    <NavDropdown.Item className="linkText" rel="noopener noreferrer" target="_blank" onClick={()=>setAdmin(true)}>Upload Stories</NavDropdown.Item>
                    <NavDropdown.Item className="linkText" onClick={()=>setShow("flex")}>Sign Out</NavDropdown.Item>
                    <NavDropdown.Item className="linkText" onClick={()=>setFeed(true)}>FILMMAKER PORTAL</NavDropdown.Item>
                  </>
                  :
                  <>
                    <NavDropdown.Item className="linkText" onClick={()=>setShow("flex")}>Upload Stories</NavDropdown.Item>
                    <NavDropdown.Item className="linkText" onClick={()=>setShow("flex")}>Sign In</NavDropdown.Item>
                    <NavDropdown.Item className="linkText" onClick={()=>setFeed(true)}>FILMMAKER PORTAL</NavDropdown.Item>
                  </>
                }
                <NavDropdown.Item className="linkText" rel="noopener noreferrer" target="_blank" href={PATREON_URL}>Support us</NavDropdown.Item>
            </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
        </div>
      </Navbar>
    </>
  );
}

export default NavHeader;
