import React from "react"
import Style from './Media.module.scss'
import Article from "./Article"
import thumbNail1 from '../imgs/Media-Page-Thumbnail-1.jpg'  
import thumbNail2 from '../imgs/Media-Page-Thumbnail-2.jpg' 
import thumbNail3 from '../imgs/Media-Page-Thumbnail-3.jpg'
import thumbNail4 from '../imgs/Media-Page-Thumbnail-4.jpg'
import thumbNail5 from '../imgs/Media-Page-Thumbnail-5.jpg'  
import thumbNail6 from '../imgs/Media-Page-Thumbnail-6.jpg' 
import thumbNail7 from '../imgs/Media-Page-Thumbnail-7.jpg'
import thumbNail8 from '../imgs/Media-Page-Thumbnail-8.jpg'
import thumbNail9 from '../imgs/Media-Page-Thumbnail-9.jpg'
import thumbNail10 from '../imgs/Media-Page-Thumbnail-10.jpg'
import article1 from '../imgs/Screenshot-Harpers-Mansion-Berrima-Couple.png'
import article2 from '../imgs/pexels-andrea-piacquadio-3768131.jpg'




const Media = () => (
    <div>
        <div id="media" className={Style.link}>&nbsp;</div>

        <div className={Style.heading}>
            Media<br/>
         </div>

        <div className={Style.container}>
            <a href="#localHistory" className={Style.tile} style={{backgroundImage: `url(${thumbNail1})`}} >
                <div className={Style.text}>The importance of local history</div>
            </a>

            <a href="#futureGenerations" className={Style.tile} style={{backgroundImage: `url(${thumbNail2})`}} >
                <div className={Style.text}>Stories are for future generations</div>
            </a>

            <a href="#appLaunches" className={Style.tile} style={{backgroundImage: `url(${thumbNail3})`}} >
                <div className={Style.text}>New history app launches</div>
            </a>

            <a href="#wollondillyRiver" className={Style.tile} style={{backgroundImage: `url(${thumbNail4})`}} >
                <div className={Style.text}>The origin of the Wollondilly River</div>
            </a>

            <a href="#nanjing" className={Style.tile} style={{backgroundImage: `url(${thumbNail5})`}} >
                <div className={Style.text}>Off the beaten track. Nanjing.</div>
            </a>

            <a href="#wong" className={Style.tile} style={{backgroundImage: `url(${thumbNail6})`}} >
                <div className={Style.text}>Remembering greengrocer Fred Wong</div>
            </a>

            <a href="#kembla" className={Style.tile} style={{backgroundImage: `url(${thumbNail7})`}} >
                <div className={Style.text}>History lives in Mt Kembla</div>
            </a>

            <a href="#colonial" className={Style.tile} style={{backgroundImage: `url(${thumbNail8})`}} >
                <div className={Style.text}>Camden&apos;s colonial history on film</div>
            </a>

            <a href="#wollondilly" className={Style.tile} style={{backgroundImage: `url(${thumbNail9})`}} >
                <div className={Style.text}>Visit Wollondilly with historical map app </div>
            </a>

            <a href="#explore" className={Style.tile} style={{backgroundImage: `url(${thumbNail10})`}} >
                <div className={Style.text}>Explore history in Wollongong</div>
            </a>
        
        </div>

        <Article 
            id="localHistory" 
            heading="The importance of local history" 
            img={article1} alt="Harper&apos;s Mansion in Berrima" 
            next="#futureGenerations"
            subHeadings={[

                {heading:"", 
                    text:[
                        "If we were to ask you about your local history, how much detail would you be able to give? Wherever you may live, there are lives, stories, emotions and histories that are worth keeping alive.",
                        "As we develop and build, move forward and advance it is crucial we take time to look back and remember how we got here.",
                        'Local history is perhaps some of the most forgotten histories that we have. Nations being founded, wars being won, freedoms, captivities, once in a lifetime events these are what most people would consider "major" and therefore commemorated',
                        'But to a local community major events have and continue to take place and should be remembered.',
                        "What is so important about local history? Why should it matter to you? In case you aren't already convinced, here are a few reasons."
                    ]
                },

                {heading:"1. Local history tells a story", 
                    text:[
                        "Growing up we loved to hear stories about fantasy lands, heroic tales or perhaps hilarious misfortunes. As we aged this childhood innocence faded, but our love for stories didn't need to. ",
                        "Amazing tales of real life people, families, cultures, failures and triumphs are accessible all around us. We don’t have to lose the childhood sense of wonder. In fact, we can thrive knowing the incredible local history of the places around us. You’ll develop a deeper connection, which in turn helps develop a fuller life.",
                        "The mundane around you can shift into a a whole new world as you dive into the history surrounding the community"
                ]
                },

                {heading:"2. The past helps us look forward",
                    text:[
                        "History teaches us a great deal. Everything has a history and taking advantage of that can allow us to learn and make wiser decisions moving forward. No doubt you have heard the saying “those who forget history are destined to repeat it.”",
                        "We live in a time where we can look around, re-live the past, experience the present and plan for the future all in the same day. Learning local history can provide great insight that otherwise would be lacking. ",
                        "History, one of life’s greatest teachers, is all around us, we just have to be willing to learn."
                    ]
                },

                {heading:"3. YOU are local history!", 
                    text:[
                        "You might not realise it, but your choices and life are forging a path. This path once trod by us, will continue on in some way. Those that carry it forward will look back at our lives and take lessons, memories and hopefully some cautions. You’ll eventually become the local history. We each have a choice now, to make it a meaningful one. By learning local history we’ll appreciate our own lives better - plus history can be a lot of fun! ",
                        "We can each gain a greater appreciation of our own lives by remembering the times before us. ",
                        "Loving history can take time, but at the very least embracing local history is a simple and free action we can make which can have profound impacts. History is made by everyone, for everyone and we can dive into the historical depths of the incredible world around us easier than ever.",
                        "Here at Yesterday Stories we know local history is not only important, but we also aim to make it accessible. Download our free app today and discover your important local history!"
                    ]
                },
            ]}
        />


        <Article 
            id="futureGenerations" 
            heading="Stories are for Future Generations" 
            img={article2} alt="Stories are for Future Generations" 
            next="#appLaunches"
            subHeadings={[

                {heading:"Humans love stories.", 
                    text:[
                        "Whether you are old or young, there are stories from you life to be shared. Humans love stories. We read, watch and listen to real and not-so-real stories all the time. People drew pictures on caves in order to tell stories and ensure knowledge was passed on. As history progressed people wrote books, massive libraries worth of books, that contained all sorts of information, histories and stories. Then the ability to record sound rocked the world and stories were told, recorded and replayed over and over.",
                        "Then we progressed even further. We filmed home videos, speeches, world events and funny Tik Toks. Videos have become one of the primary ways we communicate, especially telling stories.",
                        "As the world continues on the fast paced journey littered with videos and filming almost everything in sight it is important to pause and reflect on stories that are important. Taking time to remember how we got to here, not just as a civilisation but also as individuals.",
                        "Life moves quickly and we don’t all have the time to sit and read memoirs, let alone write ones, But with the advancement of technology and the ability to film videos it allows us to share and watch all kinds of personal and national histories.",
                        "Yesterday Stories paves the way for a unique kind of video experience. Where you can share histories that would otherwise be forgotten. Local histories are an essential part of communities and by sharing and engaging with them we can all learn more.",
                        "As we find ourselves opting to travel more domestically, you can discover fascinating stories in towns you visit, elevating your travel experience to a whole new level. You can also opt to be a part of the history by sharing or contributing to video stories for your local area.",
                        "How much do you individually know about the area you live? The local cafes, stores or sites? At the touch of screen the world around you can take on a whole new view as you dive into the history of the what, where and why",
                        "One fascinating and exciting way Yesterday Stories is helping people not only discover histories, but also preserve important memories is by allowing people to film and post stories relating to gravestones. Each stone represents a life lived and worth sharing.",
                        "As generations pass, the stone, what it acknowledges and the memories forged can pass as well. But your children and children’s children can remember on through the power of Yesterday Stories. So much time is spent filming funny videos, looking at interesting snippets on social platforms, but what about instead of a simple “we remember” post on Facebook, you could literally remember and share through an engaging and interactive video.",
                        "Time will continue marching forward, but you can choose to postmark events, locations and lives through the power of technology. Especially as young people often connect more with technology with people, finding a way to bridge the gap to give all generations and exciting love of history and the stories, lives and people that made it happen."

                    ]
                }
            ]}
        />

        <Article 
            id="appLaunches" 
            heading="New history app launches" 
            img={thumbNail3} alt="App launch" 
            next="#wollondillyRiver"
            subHeadings={[

                {heading:"", 
                    text:[
                        `From paranormal activity around Bulli to an Indigenous whale cave at Mount Kembla and the stories from the Illawarra's migrant heritage, they're all part of a new interactive history app launched this week.`,
                        `It's been five years of hard work, tears, and love, but filmmaker Sandra Pires has finally got her start-up running - Yesterday Stories - an app showcasing the stories and history of the Illawarra and other NSW towns.`,
                        `People can watch the videos of historic tales or upload their own, which she said would encourage everyone to become a filmmaker too.`,
                        `"We had the idea 10 years ago and that was before the technology was available, it's taken that long to get to this point," Ms Pires said.`,
                        `"What this platform is doing is democratising the storytelling.`,
                        `"People can upload their own stories to the platform - whether that be their personal history, their family history, the street , buildings, paranormal, .. Indigenous military history, colonial, migrants - everybody can upload their story and tell it their own way."`,
                        `Tales from Wollongong are the first to be launched with other areas to go online soon including Bega, Goulburn, Berrima, Young, Canberra, Deniliquin, Haymarket, Paramatta, Lithgow and more.`
                    ]
                }
            ]}
        />


        <Article 
            id="wollondillyRiver" 
            heading="The origin of the Wollondilly River" 
            img={thumbNail4} alt="Wollondilly River" 
            next="#nanjing"
            subHeadings={[

                {heading:"", 
                    text:[
                        `The Wollondilly River was originally a tributary of the Warragamba River, and hence of the Hawkesbury–Nepean catchment. Following the construction of the Warragamba Dam across the Warragamba River, today the river flows into Lake Burragorang, the major water supply for the greater Sydney region.`
                    ]
                }
            ]}
        />

        <Article 
            id="nanjing" 
            heading="Off the beaten track" 
            img={thumbNail5} alt="Nanjing" 
            next="#wong"
            subHeadings={[

                {heading:"App uses video stories by residents to draw visitors to places off the beaten track", 
                    text:[
                        `Filmmaker Sandra Pires’ company has produced a smartphone app for visitors to Australia that showcases her work and that of a growing number of other contributors who think lesser-known, everyday, more personal histories need wider exposure.`,
                        `“I’ve always produced documentaries about people who haven’t been able to get their story out, or the underdog – the stories that needed more attention,” says Pires, CEO of Yesterday Stories. `,
                        `The app, also called Yesterday Stories (downloadable from the usual app stores), is no tourism promotion vehicle with drone-heavy footage of Australia’s beauty spots, bungee jumping and beachside barbecues, but a collection of professionally produced accounts, mostly two or three minutes long, that fill in the history and personality of places you may not have thought of visiting.
                        `    
                    ]
                }
            ]}
        />


        <Article 
            id="wong" 
            heading="Remembering greengrocer Fred Wong" 
            img={thumbNail6} alt="Fred Wong" 
            next="#kembla"
            subHeadings={[

                {heading:"", 
                    text:[
                        `Ian Langlands describes the life of his grandfather, who opened a jewellery store in Wollongong in 1911, as "Old school - a fair dinkum watchmaker", and subtitles translate this distinctly Australian praise into "very good". Phil Hartley tells the story of pit ponies at a long-closed Mount Kembla mine, and Carol Speechley introduces some of her Illawarra ancestors' cave paintings, thousands of years old.`  
                    ]
                }
            ]}
        />

        <Article 
            id="kembla" 
            heading="History lives in Mt Kembla" 
            img={thumbNail7} alt="" 
            next="#colonial"
            subHeadings={[

                {heading:"", 
                    text:[
                        `The story of how these families used to live is one of 200 stories Pires wants to film and transfer into a website and an app that locals and tourists can use to virtually explore the region’s past.`,
                        `While visiting an area, the users’ devices (such as a phone or tablet computer) will be able to play the films showing hyper-local stories.`,
                        `“What we’re doing is collecting histories of communities and making them accessible for schools and tourists,” Pires said.`    
                    ]
                }
            ]}
        />

        <Article 
            id="colonial" 
            heading="Camden&apos;s colonial history on film" 
            img={thumbNail8} alt="" 
            next="#wollondilly"
            subHeadings={[

                {heading:"There is no doubt Camden has a long and rich history", 
                    text:[
                        `From it's colonial past, heritage listed homes, agricultural prestige and indigenous history - there is plenty to explore.`,
                        `Documentary filmmaker Sandra Pires hopes to capture some of the regions heritage through her project called Yesterday Stories.`,
                        `Ms Pires is hoping to find seeking young participants aged 15 to 25 years, who live, study or work in the Camden area to help create 15 short films.`,
                        `The short films will be made over three years and will featured on the Yesterday Stories app.`,
                        `"We are happy to be working with the Camden community on this project and also welcome the community to send in suggestions for stories," Ms Pires said.`    
                    ]
                }
            ]}
        />

        <Article 
            id="wollondilly" 
            heading="Visit Wollondilly with historical map app" 
            img={thumbNail9} alt="" 
            next="#explore"
            subHeadings={[

                {heading:"Visit Wollondilly", 
                    text:[
                        `Introducing you to your own personal pocket tour guide! At the touch of a button, enjoy a richer connection to communities when travelling to new destinations. Simply download the FREE ‘Yesterday Stories’ app and open it up at your location to discover stories including indigenous, migrant, colonial, industrial, sites and landmarks, art and monuments and personal stories. Watch the character of the community you are visiting come alive through interactive maps, videos and directions.`,
                        `Wollondilly has a few feature stories to discover on the app such as the history of The George IV Inn, insights into Mowbray Park, the dreamtime stories of Burragorang Valley and more.`    
                    ]
                }
            ]}
        />

        <Article 
            id="explore" 
            heading="Explore history in Wollongong" 
            img={thumbNail10} alt="" 
            next={false}
            subHeadings={[

                {heading:"", 
                    text:[
                        `Yesterday Stories lets you see video histories in location where history happened across the Illawarra and beyond.`,
                        `It can be difficult to find information about a new area where you are travelling to. Museums and galleries have strict closing times, Indigenous history may be hard to find and personal stories are always the most intriguing! Find out about the local community today.`,
                        `The advertising portal on Yesterday Stories is now open! Register on yesterdaystories.com.au or download the app via the QR code and reach the tourist market in location for as little as $1.99/month. Geo location marketing is a growing industry.`,
                        `You can also tell your video histories and upload to the platform free or get in touch with our team to suggest a local history story.`    
                    ]
                }
            ]}
        />
 
    </div>
)
export default Media;