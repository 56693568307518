import React from 'react'
import logo from "./imgs/footerlogo.png";
import style from "./EndFooter.module.scss"
import { TERMS_AND_CONDITIONS_URL, PRIVACY_POLICY_URL, PATREON_URL } from './constants/url.constants';
import Style from './pages/Product.module.scss';
import cityLogo from "./imgs/cityCouncil.png"
import nswLogo from "./imgs/nswLogo.png"

const Footer = () => {
    return (
        <footer className={style.footerWrapper}>
            <div className={style.patreonText}>
                <p>If you would like to support our work you can assist through&nbsp;&nbsp;
                    <button className={style.patreonButton}>
                        <a href={PATREON_URL} target='_blank' rel='noopener noreferrer'
                           className={Style.patreonLink}>&nbsp;Patreon&nbsp;</a>
                    </button>
                </p>
            </div>
            <div className={style.footerStyle}>
                <div className={style.footerSponsorWrapper}>
                    <div className={style.footerSponsorLogoWrapper}>
                        <img className={style.sponsorLogo} src={nswLogo} alt="yesterday-stories-logo" />
                        <img className={style.sponsorLogo} src={cityLogo} alt="yesterday-stories-logo" />
                    </div>
                    <p>Proudly funded by NSW Government in association with <br/> Wollongong City Council</p>
                </div>
                <div>
                    <div className={style.footerRow1}>
                        <div className={style.footerLinkCol1}>
                            <a href="#product"><p>Product</p></a>
                            <a href="#media"><p>Media</p></a>
                            <a href="#education"><p>Education</p></a>
                            {/* <a href="#filmComp"><p>Film Competition</p></a> */}
                        </div>
                        <div className={style.footerLinkCol2}>
                            <a href={TERMS_AND_CONDITIONS_URL} target="_blank" rel="noreferrer noopener"><p>Terms &amp; Conditions</p></a>
                            <a href={PRIVACY_POLICY_URL} target="_blank" rel="noreferrer noopener"><p>Privacy Policy</p></a>
                            <a href="#faq"><p>FAQs</p></a>
                        </div>
                    </div>
                </div>
                <div className={style.lastLine}>
                    <div className={style.footerLogoWrapper}>
                        <a href="/"><img className={style.footerLogo} src={logo} alt="yesterday-stories-logo" /></a>
                    </div>
                    <p>Copyright &copy; {new Date().getFullYear()} Yesterday Stories PTY LTD</p>
                    <a href="mailto:info@yesterdaystories.com.au">info@yesterdaystories.com.au</a>
                </div>
            </div>
        </footer>
    )
}
export default Footer;