import React from 'react';
import Style from './Article.module.scss'


function Article(props){
    
    const text = props.subHeadings.map((para) => 
        <div className={Style.textConatiner}>
            <div className={Style.subHeading}>{para.heading}</div>
            {para.text.map(subText => <p className={Style.text}>{subText}</p> )}
        </div>
        );
    
    return(
        <div className={Style.container}>

            <div id={props.id} className={Style.heading}>{props.heading}</div>
            <img className={Style.img} src={props.img} alt={props.alt} />
            <p>{props.alt}</p>
            
            {text}

            <div className={Style.footer}>
                <a className={Style.footerText} href="#media">&#x0003C;Return to all articles</a>
                {props.next && <a className={Style.footerText} href={props.next}>Read next article&#x0003E;</a>}
            </div>

        </div>
    );
}

export default Article;